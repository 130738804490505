<template>
    <div class="home">
        <div class="top flex">
            <img @click="goHome('')" class="logo" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" style="color:#45B79C; font-weight: bold">赛事报名</div>
            </div>
        </div>
        <div class="main">
            <div class="content">
                <div class="title">注册</div>
                <div class="line"></div>
                <div class="flex group" v-for="(item,index) in formList" :key="index">
                    <div class="part1">{{item.name}}<span v-if="item.mandatory" style="vertical-align:super; color: red">*</span></div>
                    <input @blur="checkpass(index)" :class="[item.error ? 'redborder' : '']" :type="item.type" v-model="item.value">
                    <div class="part3">
                      <!-- <div class="yzm" @click="getCode" v-if="item.yzm && !isCountdown">发送验证码</div>
                      <div class="yzm" v-if="item.yzm && isCountdown">{{time}}</div> -->
                      <div class="yzm"  @click="judge" v-if="item.yzm">{{time}}</div>

                      <div v-if="item.error" class="error"><i class="el-icon-error"></i>密码不能低于6位数</div>
                    </div> 
                </div>
                <div class="checkbox-text"><el-checkbox v-model="checked"></el-checkbox>已同意并阅读了<router-link :to="{path:'/regulations'}"  target="_blank"><span style="text-decoration:underline; color: #45B79C">《用户隐私条列》</span></router-link></div>

                <div class="flex group">
                    <div class="part1"></div>
                    <div v-if="formList[0].value && formList[1].value && formList[2].value  && formList[3].value  && !formList[5].error && formList[6].value && checked" class="registered-btn" @click="VerCode">立即注册</div>
                    <div v-else class="registered-btn no-registered-btn">立即注册</div>
                    <div class="part3"></div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
        </div>

    </div>
</template>
<script>
import { service } from '@/util/api.js'

export default {
    data() {
        return {
            formList: [
                {name: '手机号', mandatory: true, type: 'text', vlue: '', error: false},
                {name: '验证码', mandatory: true, type: 'text', yzm: true, vlue: '', error: false},
                {name: '姓名', mandatory: true, type: 'text', vlue: '', error: false},
                {name: '身份证号', mandatory: true, type: 'text', vlue: '', error: false},
                {name: '邮箱', mandatory: false, type: 'text', vlue: '', error: false},
                {name: '密码', mandatory: true, type: 'password', vlue: '', error: false},
                {name: '确认密码', mandatory: true, type: 'password', vlue: '', error: false},
            ],
            isCountdown: false, //倒计时状态
            time: '发送验证码', //倒计时 
            currentTime: 61, // 当前倒计时
            checked: false,

        }
    },
    methods: {
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
        //倒计时
        getCode() {
          this.isCountdown = true;
            let that = this;
                let clock = setInterval(function(){
                that.currentTime--;
                that.time = that.currentTime + 's'
                if (that.currentTime <= 0) {
                    clearInterval(clock)
                    that.time = '发送验证码',
                    that.currentTime = 61,
                    that.isCountdown = false

                }
            }, 1000); //一秒执行一次
        },
        checkpass(index) {
          if(index == 5) {
            this.formList[5].error = this.formList[5].value && this.formList[5].value.length >5? false : true
          }
        },
        //发送验证码
        async GetCode() {
          let parmas = {
            methodName: 'GetCode',
            phone: this.formList[0].value
          }
          let res = await service(parmas)
        },
        // 验证验证码
        async VerCode() {
          let parmas = {
            methodName: 'VerCode',
            code: this.formList[1].value,
            phone: this.formList[0].value,
          }
          let res = await service(parmas)
          if(res.Value.State) {
            this.Register()
          }else {
            this.$message.error('验证码错误');
          }
        },
        //发送验证码按钮
        judge() {
          if(this.time == '发送验证码') {
            this.getCode()
            this.GetCode()
          }
        },

        // 注册
        async Register() {
          if(this.formList[5].value == this.formList[6].value) {
            let parmas = {
              methodName: 'Register',
              userName: this.formList[0].value,
              password: this.formList[5].value,
              name: this.formList[2].value,
              idCard: this.formList[3].value,
              phone: this.formList[0].value,
              email: this.formList[4].value,
            }
            let res = await service(parmas)
            if(res.Value.State) {
              this.$message.success(res.Value.Tips);
              setTimeout(()=> {
                this.$router.push('/sign')
              }, 1000 )
              
            }else {
              this.$message.error(res.Value.Tips);
            }
          }else {
            this.$message.error('密码输入不一致！');
          }

        }

    },
    
}
</script>
<style>

.el-message {
    font-size: 15px;
}
</style>

<style lang="less" scoped>
/deep/.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #45B79C;
    border-color: #45B79C;
}
/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #ABACB1;
    font-size: 12px;

}
/deep/.el-checkbox__input {
  margin-right: 6px;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #45B79C;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
    background: #F7F7F7;
  margin: 0 auto;
  width: 1920px;
  .top {
      background: #FFFFFF;
    height: 134px;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .main {
        height: 770px;
        width: 1200px;
        background: url('~@/assets/registered/bg@2x.png');
        background-size: 100% 100%;
        margin: 19px auto;
        padding: 19px 0;
        .content {
            width: 604px;
            margin: 0 auto;
            .title {
                font-size: 24px;
                color: #45B79C;
                font-weight: bold;
                margin: 26px 0;
            }
            .line {
                width: 604px;
                height: 1px;
                background: #E5E5E5;
                margin-bottom: 40px;
            }
            .group {
                margin: 24px 0;
                .part1 {
                    font-size: 18px;
                    color: #231815;
                    width: 103px;
                    text-align: right;
                }
                .redborder {
                    border: 1px solid #F54531;
                }
                .part3 {
                  .yzm {
                    width: 136px;
                    line-height: 46px;
                    background: #45B79C;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-left: 20px;
                  }
   
                  .error {
                    font-size: 12px;
                    color: #F54531;
                    text-align: left;
                    margin-left: 16px;
                  }
                }
                input {
                    width: 274px;
                    height: 46px;
                    border: 1px solid #DCDCDC;
                    margin-left: 21px;
                    font-size: 18px;
                    padding: 0 24px;
                }
                .no-registered-btn {
                  opacity: 0.5;
                }
                .registered-btn {
                    width: 322px;
                    line-height: 46px;
                    background: #45B79C;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-left: 21px;
                }
            }
            .checkbox-text {
                font-size: 12px;
                color: #ABACB1;
                // margin: 21px 0 14px 0;
                margin-left: 125px;
                text-align: left;
            }
        }
    }
  .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

  }
}
</style>


